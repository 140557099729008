import React, { useEffect, useState } from 'react'

const BedaMarketplace: React.FC = () => {
  const [cardsData, setCardsData] = useState<any>([]);

  let [htmlFileString, setHtmlFileString] = useState<any>();

  async function fetchHtml() {
		setHtmlFileString(await(await fetch(`BedaMarketplace.html`)).text());
  }
  useEffect(() => {
		fetchHtml();
  }, []);

  return (
		<div>
			<div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div>
		</div>
  );

}

export default BedaMarketplace