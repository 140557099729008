import React, { useEffect, useState } from "react";

function Home() {
	const [cardsData, setCardsData] = useState<any>([]);

	let [htmlFileString, setHtmlFileString] = useState<any>();

	async function fetchHtml() {
		setHtmlFileString(await (await fetch(`Beda.html`)).text());
	}
	useEffect(() => {
		fetchHtml();
	}, []);

	return (
		<div>
			<div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div>
		</div>
	);
}

export default Home;
