import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";

interface SearchResult {
	content: string;
}

const SearchContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem;
`;

const SearchInput = styled.input`
	padding: 0.5rem;
	font-size: 1rem;
	border: 1px solid #ccc;
	border-radius: 0.25rem;
	margin-bottom: 1rem;
	width: 100%;
	max-width: 750px;
`;

const SearchButton = styled.button`
	padding: 0.5rem 1rem;
	font-size: 1rem;
	background-color: #0077b6;
	color: #fff;
	border: none;
	border-radius: 0.25rem;
	cursor: pointer;
	transition: background-color 0.3s ease;

	&:hover {
		background-color: #023e8a;
	}
`;

const ResultContainer = styled.div`
	margin-top: 2rem;
	width: 100%;
	max-width: 800px;
`;

const ResultItem = styled.div`
	padding: 1rem;
	background-color: #f5f5f5;
	border-radius: 0.25rem;
	margin-bottom: 1rem;
`;

const SearchPage: React.FC = () => {
	const [query, setQuery] = useState("");
	const [results, setResults] = useState<string[]>([]);

	const handleSearch = async () => {
		try {
			const response = await axios.post<{
				semantic_results: string[];
			}>("http://localhost:8000/semantic-search/", { query });
			setResults(response.data.semantic_results);
		} catch (error) {
			console.error("Error fetching search results:", error);
		}
	};

	return (
		<SearchContainer>
			<h1>Beda Top</h1>
			<SearchInput
				type="text"
				value={query}
				onChange={(e) => setQuery(e.target.value)}
				placeholder="Enter your search query"
			/>
			<SearchButton onClick={handleSearch}>Search</SearchButton>
			<ResultContainer>
				{results.map((result, index) => (
					<ResultItem key={index}>
						<p>
                            {result.split("\n").map((res, index) => (
                            <React.Fragment key={index}>
                                {res}
                                <br />
                            </React.Fragment>
                            ))}
                        </p>
					</ResultItem>
				))}
			</ResultContainer>
		</SearchContainer>
	);
};

export default SearchPage;
