import React, { useState } from "react";
import {
	getAuth,
	RecaptchaVerifier,
	signInWithPhoneNumber,
	PhoneAuthProvider,
	signInWithCredential,
} from "firebase/auth";
import { firebaseConfig } from "../config";
import { initializeApp } from "firebase/app";
import { Button, Container, TextField, Typography, Box } from "@mui/material";

// Initialize Firebase app
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const DeleteAccount = () => {
	const [phoneNumber, setPhoneNumber] = useState("");
	const [verificationCode, setVerificationCode] = useState("");
	const [deleteError, setDeleteError] = useState(null);
	const [loggedIn, setLoggedIn] = useState(false);
	const [confirmationResult, setConfirmationResult] = useState<any>(null);

	// Function to handle login with phone authentication
	const handleLogin = async () => {
		try {
			const recaptchaVerifier = new RecaptchaVerifier(
				auth,
				"recaptcha-container",
				{
					size: "invisible",
					callback: () => {
						// Callback function after recaptcha verification
					},
				}
			);
			const result = await signInWithPhoneNumber(
				auth,
				phoneNumber,
				recaptchaVerifier
			);
			setConfirmationResult(result);
		} catch (error: any) {
			setDeleteError(error.message);
		}
	};

	// Function to handle verification code submission
	const handleVerification = async () => {
		try {
			const credential = PhoneAuthProvider.credential(
				confirmationResult.verificationId,
				verificationCode
			);
			await signInWithCredential(auth, credential);
			setLoggedIn(true);
		} catch (error: any) {
			setDeleteError(error.message);
		}
	};

	// Function to handle account deletion
	const handleDeleteAccount = async () => {
		try {
			await getAuth().currentUser?.delete();
			// Account successfully deleted
			// Redirect or show a success message
		} catch (error: any) {
			setDeleteError(error.message);
		}
	};

	return (
		<Container maxWidth="sm">
			<Box display="flex" justifyContent="center" alignItems="center" marginBottom={4}>
				<img src='logo_white.svg' alt="Beda Logo" style={{ width: 100, height: 'auto' }} />
			</Box>
			{!loggedIn && !confirmationResult ? (
				<div>
					<Typography variant="h5" gutterBottom>
						Login to delete your account on Beda
					</Typography>
					<TextField
						fullWidth
						label="Enter your phone number"
						value={phoneNumber}
						onChange={(e: any) => setPhoneNumber(e.target.value)}
						variant="outlined"
						margin="normal"
					/>
					<div id="recaptcha-container"></div>
					{deleteError && (
						<Typography variant="body1" color="error" gutterBottom>
							{deleteError}
						</Typography>
					)}
					<Button
						onClick={handleLogin}
						variant="contained"
						color="primary"
					>
						Login with Phone
					</Button>
				</div>
			) : (
				<div>
					{!loggedIn ? (
						<div>
							<Typography variant="h4" gutterBottom>
								Verification
							</Typography>
							<TextField
								fullWidth
								label="Enter verification code"
								value={verificationCode}
								onChange={(e) =>
									setVerificationCode(e.target.value)
								}
								variant="outlined"
								margin="normal"
							/>
							{deleteError && (
								<Typography
									variant="body1"
									color="error"
									gutterBottom
								>
									{deleteError}
								</Typography>
							)}
							<Button
								onClick={handleVerification}
								variant="contained"
								color="primary"
							>
								Verify
							</Button>
						</div>
					) : (
						<div>
							<Typography variant="h4" gutterBottom>
								Delete Account
							</Typography>
							<Typography variant="body1" gutterBottom>
								Are you sure you want to delete your account?
							</Typography>
							{deleteError && (
								<Typography
									variant="body1"
									color="error"
									gutterBottom
								>
									{deleteError}
								</Typography>
							)}
							<Button
								onClick={handleDeleteAccount}
								variant="contained"
								color="secondary"
							>
								Delete Account
							</Button>
						</div>
					)}
				</div>
			)}
		</Container>
	);
};

export default DeleteAccount;
