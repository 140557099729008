import React from "react";

const TermsOfService: React.FC = () => {
	return (
		<div>
			<h1>Terms of Service</h1>
			<p>
				These Terms of Service ("Terms") govern your access to and use
				of the Beda platform for posting houses or apartments for rent
				or sale ("Platform"). By accessing or using the Platform, you
				agree to be bound by these Terms.
			</p>
			<h2>1. Acceptance of Terms</h2>
			<p>
				By accessing or using the Platform, you agree to be bound by
				these Terms and all applicable laws and regulations. If you do
				not agree with any of these Terms, you may not access or use the
				Platform.
			</p>
			<h2>2. Use of Platform</h2>
			<p>
				You must be at least 18 years old to use the Platform. By
				accessing or using the Platform, you represent and warrant that
				you are at least 18 years old. You are responsible for
				maintaining the confidentiality of your account credentials and
				for all activities that occur under your account. You agree to
				provide accurate and complete information when registering for
				an account and creating listings on the Platform.
			</p>
			<h2>3. User Content</h2>
			<p>
				You retain ownership of any content you submit, post, or display
				on the Platform ("User Content"). By submitting User Content,
				you grant Beda a worldwide, non-exclusive, royalty-free license
				to use, reproduce, modify, adapt, publish, translate,
				distribute, and display such User Content.
			</p>
			<h2>4. Prohibited Activities</h2>
			<p>
				You may not use the Platform for any illegal or unauthorized
				purpose. You agree to comply with all applicable laws and
				regulations. You may not engage in any activity that interferes
				with or disrupts the operation of the Platform.
			</p>
			<h2>5. Intellectual Property</h2>
			<p>
				The Platform and its content, including but not limited to text,
				graphics, logos, and images, are the property of Beda or its
				licensors and are protected by copyright, trademark, and other
				intellectual property laws.
			</p>
			<h2>6. Disclaimer of Warranties</h2>
			<p>
				The Platform is provided on an "as is" and "as available" basis.
				Beda makes no warranties or representations about the accuracy
				or completeness of the content on the Platform or the
				availability of any listings.
			</p>
			<h2>7. Limitation of Liability</h2>
			<p>
				In no event shall Beda be liable for any indirect, incidental,
				special, consequential, or punitive damages arising out of or
				relating to your use of the Platform.
			</p>
			<h2>8. Indemnification</h2>
			<p>
				You agree to indemnify and hold Beda harmless from any claims,
				damages, losses, liabilities, costs, and expenses arising out of
				or relating to your use of the Platform or your violation of
				these Terms.
			</p>
			<h2>9. Governing Law</h2>
			<p>
				These Terms shall be governed by and construed in accordance
				with the laws of [insert jurisdiction].
			</p>
			<h2>10. Changes to Terms</h2>
			<p>
				Beda reserves the right to modify or replace these Terms at any
				time. Any changes will be effective immediately upon posting the
				revised Terms on the Platform.
			</p>
			<h2>11. Contact Us</h2>
			<p>
				If you have any questions about these Terms, please contact us
				at <a href="mailto:contact@beda.com">bekhruzbek.rakh@gmail.com</a>.
			</p>
		</div>
	);
};

export default TermsOfService;
