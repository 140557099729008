// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
export const firebaseConfig = {
	apiKey: "AIzaSyCjjZ2XUmd2PuH56BaGdhao5xCiy_xki3k",
	authDomain: "beda-p0ryuz.firebaseapp.com",
	projectId: "beda-p0ryuz",
	storageBucket: "beda-p0ryuz.appspot.com",
	messagingSenderId: "678983845092",
	appId: "1:678983845092:web:85e505e54f2cdb2ef858f1",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
