import React from "react";

const PrivacyPolicy: React.FC = () => {
	return (
		<div>
			<h1>
				Privacy Policy for Beda which is House Rental and Sale Platform
			</h1>

			<p>
				This Privacy Policy describes how Beda ("we," "us," or "our")
				collects, uses, shares, and protects your personal information
				in connection with your use of our platform for posting houses
				or apartments for rent or sale. This policy applies to all users
				of our platform, including homeowners, renters, buyers, and any
				other individuals or entities who access or use our services.
			</p>

			<p>
				By accessing or using our platform, you consent to the
				collection, use, disclosure, and processing of your personal
				information as described in this Privacy Policy. If you do not
				agree with the terms of this Privacy Policy, please do not
				access or use our platform.
			</p>

			<h2>1. Information We Collect:</h2>

			<p>
				We collect various types of information from and about users of
				our platform, including:
			</p>

			<ul>
				<li>
					Personal Information: such as your name, email address,
					phone number, postal address, and any other information you
					provide when you register for an account or create a
					listing.
				</li>
				<li>
					Property Information: such as the address, description,
					price, and images of the property you are listing for rent
					or sale.
				</li>
				<li>
					Usage Information: such as your IP address, device type,
					browser type, operating system, and other information about
					your use of our platform collected through cookies and
					similar tracking technologies.
				</li>
				<li>
					Communications: including any communications between you and
					us, such as emails, messages, or feedback you send to us.
				</li>
			</ul>

			<h2>2. How We Use Your Information:</h2>

			<p>
				We use the information we collect for various purposes,
				including:
			</p>

			<ul>
				<li>
					To provide, maintain, and improve our platform and services.
				</li>
				<li>
					To communicate with you, respond to your inquiries, and
					provide customer support.
				</li>
				<li>
					To process and manage your listings, transactions, and
					payments.
				</li>
				<li>
					To personalize your experience and tailor the content and
					features of our platform to your interests.
				</li>
				<li>
					To detect, prevent, and address technical issues, fraud, or
					other illegal activities.
				</li>
				<li>
					To comply with legal obligations and enforce our terms of
					service and other policies.
				</li>
			</ul>

			<h2>3. How We Share Your Information:</h2>

			<p>
				We may share your personal information with third parties in the
				following circumstances:
			</p>

			<ul>
				<li>
					With service providers and vendors who assist us in
					operating our platform and providing our services, such as
					hosting providers, payment processors, and customer support
					providers.
				</li>
				<li>
					With other users of our platform, such as potential renters
					or buyers interested in your property listings.
				</li>
				<li>
					With third parties for marketing or advertising purposes,
					but only with your consent or as permitted by applicable
					law.
				</li>
				<li>
					In response to legal process or government requests, or to
					protect our rights, property, or safety, or the rights,
					property, or safety of others.
				</li>
				<li>
					In connection with a merger, acquisition, or sale of all or
					a portion of our assets.
				</li>
			</ul>

			<h2>4. Your Choices:</h2>

			<p>
				You can access, update, or delete your account and personal
				information through your account settings on our platform. You
				may also unsubscribe from marketing communications by following
				the instructions provided in such communications.
			</p>

			<h2>5. Security:</h2>

			<p>
				We take reasonable measures to protect the security of your
				personal information and to prevent unauthorized access, use, or
				disclosure. However, no method of transmission over the internet
				or electronic storage is 100% secure, so we cannot guarantee
				absolute security.
			</p>

			<h2>6. Children's Privacy:</h2>

			<p>
				Our platform is not intended for children under the age of 18,
				and we do not knowingly collect personal information from
				children under the age of 18. If we become aware that we have
				collected personal information from a child under the age of 18,
				we will take steps to delete such information.
			</p>

			<h2>7. Changes to this Privacy Policy:</h2>

			<p>
				We may update this Privacy Policy from time to time by posting a
				new version on our platform. We will notify you of any material
				changes to this Privacy Policy by email or by prominently
				posting a notice on our platform. Your continued use of our
				platform after such changes constitutes your acceptance of the
				revised Privacy Policy.
			</p>

			<h2>8. Contact Us:</h2>

			<p>
				If you have any questions or concerns about this Privacy Policy
				or our privacy practices, you may contact us at info@beda.uz.
			</p>

			<p>This Privacy Policy was last updated on March 26th.</p>
		</div>
	);
};

export default PrivacyPolicy;
